import { library } from "@fortawesome/fontawesome-svg-core";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleCheck,
  faDice,
  faFlag, // faDownLong,
  faHouse,
  faList,
  faLocationDot, // faLocationCrosshairs,
  faMagicWandSparkles,
  faMinus,
  faMoon,
  faPlus,
  faQrcode,
  faRedo,
  faSearch,
  faShuffle,
  faTableList,
  faThumbsDown,
  faTrash,
  faUndo,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  // faDownLong,
  // faLocationCrosshairs,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleCheck,
  faDice,
  faFlag,
  faGithub,
  faHouse,
  faList,
  faLocationDot,
  faMagicWandSparkles,
  faMinus,
  faMoon,
  faPlus,
  faQrcode,
  faRedo,
  faSearch,
  faShuffle,
  faTableList,
  faThumbsDown,
  faTrash,
  faUndo,
  faXmark,
);

export default {};
